import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "End-to-End Infrastructure Setup on Amazon EKS",
      description: "Setup of scalable infrastructure using Amazon EKS, Argo CD, Prometheus, and Grafana.",
      imgUrl: projImg1,
    },
    {
      title: "AWS Infrastructure Automation with Terraform",
      description: "Automated AWS infrastructure using Terraform, improving deployment efficiency and scalability.",
      imgUrl: projImg2,
    },
    {
      title: "Jenkins to Bitbucket Pipelines Migration",
      description: "Migrated Jenkins jobs to Bitbucket Pipelines, reducing deployment and rollback times by 50%.",
      imgUrl: projImg3,
    },
    {
      title: "Automated Monitoring with New Relic and CloudWatch",
      description: "Implemented automated monitoring and alerting, reducing downtime by proactively identifying issues.",
      imgUrl: projImg1,
    },
    {
      title: "Infrastructure Cost Optimization",
      description: "Optimized infrastructure resources, reducing monthly costs from $27,000 to $20,000.",
      imgUrl: projImg2,
    },
    {
      title: "Development of Self-Service Platform Scripts",
      description: "Developed automation scripts using Python, Bash, and Golang for platform management.",
      imgUrl: projImg3,
    },
    {
      title: "DevSecOps Automation",
      description: "Implemented security automation across CI/CD pipelines, improving compliance and incident response.",
      imgUrl: projImg1,
    },
    {
      title: "Scalable Application Deployment with Docker and AWS",
      description: "Deployed highly available applications using Docker, Lambda, EC2, DynamoDB, and S3.",
      imgUrl: projImg2,
    },
    {
      title: "Backend Development for TEAM-ERP Application",
      description: "Developed and optimized backend for an ERP application, supporting 7000+ daily active users.",
      imgUrl: projImg3,
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Projects</h2>
                <p> I have optimized cloud infrastructure with Amazon EKS, Terraform, and CI/CD automation, improving efficiency and cost-effectiveness. Additionally, I’ve enhanced security and platform management through DevSecOps practices and custom automation scripts</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  {/* <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Tab 1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Tab 2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Tab 3</Nav.Link>
                    </Nav.Item>
                  </Nav> */}
                  <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                    <Tab.Pane eventKey="first">
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                              />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    {/* <Tab.Pane eventKey="section">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                    </Tab.Pane> */}
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}


